// ** Third Party Imports
import styled from 'styled-components'

// ** Component Imports
import { Dialog, Heading, RadioGroup, Typography } from '@/shared/ui'

// ** Icon Imports
import { PlusCircle } from '@/shared/ui/icons'
import { media } from '@/shared/styles'

export const PlusCircleIcon = styled(PlusCircle)`
	width: 1.25rem !important;
	height: 1.25rem !important;
`

export const Content = styled(Dialog.Content)`
	max-width: 43.75rem;
`

export const Title = styled(Heading)`
	color: ${({ theme }) => theme.pallette.text.black75};
	margin-bottom: 1.5rem;
`

export const Description = styled(Typography)`
	color: ${({ theme }) => theme.pallette.text.black50};
	margin-bottom: 1.5rem;
`

export const StyledRadioGroup = styled(RadioGroup)`
	gap: 1.5rem;
	display: flex;
	align-items: center;
	margin-bottom: 1.5rem;
	flex-direction: row;
	justify-content: center;

	${media.sm} {
		flex-wrap: wrap;
		justify-content: flex-start;
	}
`

export const ButtonsWrapper = styled.div`
	gap: 1rem;
	display: flex;

	${media.sm} {
		flex-wrap: wrap;
	}
`

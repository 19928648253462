import { renderToString } from 'react-dom/server'
import { type ApexOptions } from 'apexcharts'
import { currencyFormatterNegative } from '@/shared/lib'
import { ChartTooltip } from '@/shared/ui'
import type { SeriesDTO } from '.'

/**
 * Check if every series value is 0
 */
export const getIsEmpty = (seriesData: SeriesDTO[]) => {
	return seriesData.every((series) => series.value === 0)
}

export const getOptions = (
	seriesData: SeriesDTO[],
	colors: string[],
	isCurrency?: boolean
): ApexOptions => {
	const isEmpty = getIsEmpty(seriesData)

	const labels = isEmpty ? [] : seriesData.map((series) => series.label)

	return {
		chart: {
			toolbar: {
				show: false
			}
		},
		plotOptions: {
			pie: {
				expandOnClick: false,
				donut: {
					size: '50%'
				}
			}
		},
		colors,
		dataLabels: {
			enabled: false
		},
		labels,
		legend: {
			show: false
		},
		tooltip: {
			enabled: !isEmpty,
			marker: {
				show: false
			},
			cssClass: 'custom-chart-tooltip-class',
			custom: ({ w, series, seriesIndex }) => {
				return renderToString(
					<ChartTooltip
						label={`${w.config.labels[seriesIndex]}:`}
						value={`${
							isCurrency
								? currencyFormatterNegative(series[seriesIndex], true)
								: series[seriesIndex]
						}`}
					/>
				)
			}
		},
		states: {
			hover: {
				filter: {
					type: isEmpty ? 'none' : 'lighten',
					value: isEmpty ? undefined : 0
				}
			},
			active: {
				filter: {
					type: 'none'
				}
			}
		}
	}
}

export const getSeries = (seriesData: SeriesDTO[]): number[] => {
	const isNoData = getIsEmpty(seriesData)

	if (isNoData) {
		// Return a placeholder value to fill the chart
		return [1]
	}

	return seriesData.map((series) => series.value)
}

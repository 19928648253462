// ** Styled Components
import {
	TdInner,
	StyledTh,
	StyledTr,
	StyledTd,
	Container,
	StyledTBody,
	StyledTable,
	StyledTHead,
	ScrollContainer
} from './styles'

interface TableProps {
	children?: React.ReactNode
}

interface TdProps {
	children?: React.ReactNode
	width?: string
	checkboxSelection?: boolean
}

interface TrProps {
	onClick?: React.MouseEventHandler<HTMLTableRowElement>
	children?: React.ReactNode
}

// Table component
export const Table = ({ children }: TableProps) => {
	return <StyledTable>{children}</StyledTable>
}
// Table component props
Table.Container = Container
Table.ScrollContainer = ScrollContainer

// Table cell component
export const Td = ({ children, width, checkboxSelection = false }: TdProps) => {
	return (
		<StyledTd $width={width} $checkboxSelection={checkboxSelection}>
			{children}
		</StyledTd>
	)
}
// Table cell props
Td.Inner = TdInner

export const Tr = ({ onClick, children }: TrProps) => {
	return <StyledTr onClick={onClick}>{children}</StyledTr>
}

// Table head cell
export const Th = StyledTh
// Table head wrapper
export const THead = StyledTHead
// Table body
export const TBody = StyledTBody

import styled from 'styled-components'
import { Menu } from '@/shared/ui/icons'

export const StyledButton = styled.button`
	width: 2.5rem;
	height: 2.5rem;
	border-radius: 50%;
	padding: 0.4rem;
	background-color: transparent;
	cursor: pointer;
	outline: none;
	border: none;
`

export const MenuIcon = styled(Menu)`
	width: 100%;
	height: 100%;
	path {
		stroke: ${({ theme }) => theme.pallette.geniiBlack};
	}
`

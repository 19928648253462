// ** Third Party Imports
import { Navigate, type RouteObject } from 'react-router-dom'

// ** Utility Imports
import { lazyImport } from '@/shared/lib'

// ** Layout Imports
import { LayoutWithSidebar } from '@/app/layouts/LayoutWithSidebar'

// ** Route Imports
const { DashboardPage } = lazyImport(
	async () => await import('@/pages/dashboard'),
	'DashboardPage'
)
const { ClientManagementPage } = lazyImport(
	async () => await import('@/pages/client-management'),
	'ClientManagementPage'
)
const { CreateClientPage } = lazyImport(
	async () => await import('@/pages/client-management'),
	'CreateClientPage'
)
const { EditClientPage } = lazyImport(
	async () => await import('@/pages/client-management'),
	'EditClientPage'
)
const { CreateCompanyPage } = lazyImport(
	async () => await import('@/pages/company-management'),
	'CreateCompanyPage'
)
const { EditCompanyPage } = lazyImport(
	async () => await import('@/pages/company-management'),
	'EditCompanyPage'
)
const { BusinessSummaryPage } = lazyImport(
	async () => await import('@/pages/client-management'),
	'BusinessSummaryPage'
)
const { TaxStrategiesPage } = lazyImport(
	async () => await import('@/pages/tax-strategies'),
	'TaxStrategiesPage'
)
const { TaxStrategyPage } = lazyImport(
	async () => await import('@/pages/tax-strategies'),
	'TaxStrategyPage'
)
const { CreatePlanPage } = lazyImport(
	async () => await import('@/pages/plan-management'),
	'CreatePlanPage'
)
const { EditPlanPage } = lazyImport(
	async () => await import('@/pages/plan-management'),
	'EditPlanPage'
)
const { PlanReportPage } = lazyImport(
	async () => await import('@/pages/plan-management'),
	'PlanReportPage'
)
const { ManagePlanDetailsPage } = lazyImport(
	async () => await import('@/pages/plan-management'),
	'ManagePlanDetailsPage'
)
const { ClientDashboardPage } = lazyImport(
	async () => await import('@/pages/client-dashboard'),
	'ClientDashboardPage'
)
const { UserProfilePage } = lazyImport(
	async () => await import('@/pages/user-profile'),
	'UserProfilePage'
)
const { Error404Page } = lazyImport(
	async () => await import('@/pages/error-404'),
	'Error404Page'
)

export const protectedRoutes: RouteObject[] = [
	{
		element: <LayoutWithSidebar />,
		children: [
			{
				path: '/user-profile',
				element: <UserProfilePage />
			},
			{
				path: '/',
				element: <DashboardPage />
			},
			{
				path: '/client-management',
				element: <ClientManagementPage />
			},
			{
				path: '/client-management/:id',
				element: <EditClientPage />
			},
			{
				path: '/business-summary/:id',
				element: <BusinessSummaryPage />
			},
			{
				path: '/client-dashboard',
				element: <ClientDashboardPage />
			},
			{
				path: '/create-client',
				element: <CreateClientPage />
			},
			{
				path: '/create-company/*',
				element: <CreateCompanyPage />
			},
			{
				path: '/edit-company/*',
				element: <EditCompanyPage />
			},
			{
				path: '/create-plan/*',
				element: <CreatePlanPage />
			},
			{
				path: '/plan-details/:clientId/:planId/',
				element: <ManagePlanDetailsPage />
			},
			{
				path: '/plan-details/:clientId/:planId/edit',
				element: <EditPlanPage />
			},
			{
				path: '/plan-details/:clientId/:planId/report',
				element: <PlanReportPage />
			},
			{
				path: '/tax-strategies',
				element: <TaxStrategiesPage />
			},
			{
				path: '/tax-strategies/:id',
				element: <TaxStrategyPage />
			},
			{
				path: '/login',
				element: <Navigate to='/' replace />
			},
			{
				path: '*',
				element: <Error404Page />
			}
		]
	}
]

export interface UserDTO {
	id: number
	email: string
	lastName: string | null
	phoneNum: string
	firstName: string | null
	isAccountActivated: boolean
	photoKey: 'string'
	role: UserRoleDTO
	organizationId: number
}

export const enum UserRoleCode {
	SUPERADMIN = 1694,
	ADMIN = 4631,
	OWNER = 2540,
	MEMBER = 3480
}

export const enum UserRoleName {
	SUPERADMIN = 'Superadmin',
	ADMIN = 'Admin',
	MEMBER = 'Member',
	OWNER = 'Owner'
}

export interface UserRoleDTO {
	code: UserRoleCode
	name: UserRoleName
}

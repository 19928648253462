export const TAGS = {
	ME: 'Me',
	USERS: 'Users',
	COMPANY_BUSINESS_INFO: 'CompanyBusinessInfo',
	CLIENT_TAX_PLANS: 'ClientTaxPlans',
	TAX_PLAN: 'TaxPlan',
	TAX_PLAN_FEES: 'TaxPlanFees',
	TAX_PLAN_FEE: 'TaxPlanFee',
	TAX_PLAN_STRATEGIES: 'TaxPlanStrategies',
	TAX_PLAN_SIMULATOR: 'TaxPlanSimulator',
	ORGANIZATION: 'Organization',
	CHAT_HISTORIES_TABLE: 'ChatHistoriesTable',
}

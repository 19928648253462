// ** React Imports
import { type ReactNode } from 'react'

// ** Third Party Imports
import * as ReactRadioGroup from '@radix-ui/react-radio-group'

interface IProps {
	value?: string
	children?: ReactNode
	defaultValue?: string
	onValueChange?: (value: string) => void
}

export const RadioGroup = ({ children, ...props }: IProps) => {
	return <ReactRadioGroup.Root {...props}>{children}</ReactRadioGroup.Root>
}

import styled, { type CSSProperties } from 'styled-components'

export const LoaderWrapper = styled.div<{
	$styles?: CSSProperties
	$divStyles?: CSSProperties
}>`
	display: inline-block;
	position: relative;
	width: 5rem;
	height: 5rem;

	& div {
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		/**
      Variable isn't used here because this loader is also used inside AuthContext,
      and AuthContext is a parent of ThemeProvider component so theme
      isn't available here
    */
		border: 8px solid #096a63;
		${({ $divStyles }) => ({ ...$divStyles })}
		border-radius: 50%;
		animation: spinner 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		border-color: #096a63 transparent transparent transparent;

	}

	& div:nth-child(1) {
		animation-delay: -0.04s;
	}
	& div:nth-child(2) {
		animation-delay: -0.03s;
	}
	& div:nth-child(3) {
		animation-delay: -0.02s;
	}
	& div:nth-child(4) {
		animation-delay: -0.01s;
	}

	@keyframes spinner {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
	${({ $styles }) => ({ ...$styles })}
`

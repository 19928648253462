// ** React Imports
import { type ForwardedRef, forwardRef } from 'react'

// ** Third Party Imports
import * as Form from '@radix-ui/react-form'

// ** Styled Components
import { StyledAnchor, StyledButton, StyledLink } from './styles'

interface IProps {
	to?: string
	href?: string
	form?: string
	/**
	 * Custom inline styling
	 */
	style?: React.CSSProperties | undefined
	/**
	 * Button type:
	 * If the button type is submit,
	 * then the button must be wrapped with a form component,
	 * and the form component must have na onSubmit handler.
	 */
	type?: 'button' | 'submit'
	/**
	 * Button size
	 */
	size?: 'normal' | 'small'
	/**
	 * Button type
	 */
	variant?: 'primary' | 'secondary' | 'text'
	/**
	 * Button contents
	 */
	label: string
	/**
	 * Button color
	 */
	color?: 'primary' | 'secondary' | 'success' | 'error' | 'warning'
	/**
	 * Optional click handler
	 */
	onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>
	/**
	 * Custom css class
	 */
	className?: string
	/**
	 * Disable button
	 */
	disabled?: boolean
	/**
	 * Content width or full width
	 */
	fullWidth?: boolean
	/**
	 * Button start icon
	 */
	startIcon?: JSX.Element
	/**
	 * isLoading indicator
	 */
	isLoading?: boolean
}

/**
 * Primary UI component for user interaction
 */
export const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, IProps>(
	(
		{
			to,
			href,
			size = 'normal',
			type = 'button',
			style,
			label,
			color = 'primary',
			variant = 'primary',
			onClick,
			className,
			disabled = false,
			fullWidth = false,
			startIcon,
			isLoading,
			...commonProps
		},
		ref
	) => {
		if (to !== undefined) {
			return (
				<StyledLink
					to={to}
					ref={ref as ForwardedRef<HTMLAnchorElement>}
					size={size}
					style={style}
					color={color}
					variant={variant}
					onClick={onClick}
					className={className}
					$fullWidth={fullWidth}
					disabled={disabled}
					{...commonProps}
				>
					{startIcon !== undefined && startIcon}
					{label}
				</StyledLink>
			)
		}

		// <FallbackLoader />

		if (href !== undefined) {
			return (
				<StyledAnchor
					ref={ref as ForwardedRef<HTMLAnchorElement>}
					href={href}
					size={size}
					style={style}
					color={color}
					target='_blank'
					variant={variant}
					className={className}
					$fullWidth={fullWidth}
					disabled={disabled}
					{...commonProps}
				>
					{startIcon !== undefined && startIcon}
					{label}
				</StyledAnchor>
			)
		}

		return (
			<>
				{type === 'submit' ? (
					<Form.Submit asChild>
						<StyledButton
							ref={ref as ForwardedRef<HTMLButtonElement>}
							size={size}
							type='submit'
							style={style}
							color={color}
							variant={variant}
							className={className}
							$fullWidth={fullWidth}
							disabled={disabled}
							{...commonProps}
						>
							{startIcon !== undefined && startIcon}
							{label}
							{isLoading && '...'}
						</StyledButton>
					</Form.Submit>
				) : (
					<StyledButton
						ref={ref as ForwardedRef<HTMLButtonElement>}
						size={size}
						type='button'
						style={style}
						color={color}
						variant={variant}
						onClick={onClick}
						className={className}
						$fullWidth={fullWidth}
						disabled={disabled}
						{...commonProps}
					>
						{startIcon !== undefined && startIcon}
						{label}
						{isLoading && '...'}
					</StyledButton>
				)}
			</>
		)
	}
)

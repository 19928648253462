// ** React Imports
import { useState } from 'react'

// ** Third Party Imports
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'

// ** Component Imports
import {
	Button,
	Dialog,
	FormControl,
	FormHelperText,
	InputField,
	Loader
} from '@/shared/ui'

// ** Schema & Type Imports
import { schema } from '../model/schema'
import type { ScheduleDemoSchema } from '../model/types'

// ** Styled Components
import {
	ButtonsWrapper,
	Content,
	Description,
	FieldsWrapper,
	Form,
	LoaderWrapper,
	Title,
	TriggerButton
} from './styles'

// ** Action Imports
import { useScheduleDemoMutation } from '../api/scheduleDemo'

export const ScheduleDemoButton = () => {
	// ** State
	const [open, setOpen] = useState(false)

	// ** Hooks
	// Mutations
	const [scheduleDemo, { reset, isSuccess, isLoading }] =
		useScheduleDemoMutation()
	const {
		reset: resetForm,
		control,
		trigger,
		handleSubmit,
		formState: { errors, isValid }
	} = useForm<ScheduleDemoSchema>({
		mode: 'onChange',
		resolver: yupResolver(schema),
		shouldFocusError: false
	})

	const handleOpenChange = (open: boolean) => {
		reset()
		resetForm()
		setOpen(open)
	}

	const onSubmit = (formData: ScheduleDemoSchema) => {
		scheduleDemo(formData)
	}

	return (
		<Dialog open={open} onOpenChange={handleOpenChange}>
			<Dialog.Trigger>
				<TriggerButton label='Get a Demo' />
			</Dialog.Trigger>
			<Content>
				<Dialog.Close />
				<Title variant='h2' weight='semi-bold'>
					{isSuccess ? 'Demo successfully scheduled!' : 'Schedule a Free Demo'}
				</Title>
				{isLoading ? (
					<LoaderWrapper>
						<Loader />
					</LoaderWrapper>
				) : isSuccess ? (
					<Description variant='body2' weight='medium'>
						Your submission has been received. We will be in touch shortly.
					</Description>
				) : (
					<>
						<Description variant='body2' weight='medium'>
							Discover how FortunaAI can not only simplify tax planning but also
							save your clients thousands of dollars.
						</Description>
						<Form onSubmit={handleSubmit(onSubmit)}>
							<FieldsWrapper>
								<FormControl name='firstName'>
									<Controller
										name='firstName'
										control={control}
										render={({ field }) => (
											<InputField
												{...field}
												type='firstName'
												label='First Name'
												trigger={trigger}
												fullWidth
											/>
										)}
									/>
									{errors.firstName !== undefined && (
										<FormHelperText color='error'>
											{errors?.firstName?.message}
										</FormHelperText>
									)}
								</FormControl>
								<FormControl name='lastName'>
									<Controller
										name='lastName'
										control={control}
										render={({ field }) => (
											<InputField
												{...field}
												type='lastName'
												label='Last Name'
												trigger={trigger}
												fullWidth
											/>
										)}
									/>
									{errors.lastName !== undefined && (
										<FormHelperText color='error'>
											{errors?.lastName?.message}
										</FormHelperText>
									)}
								</FormControl>
							</FieldsWrapper>
							<FieldsWrapper>
								<FormControl name='email'>
									<Controller
										name='email'
										control={control}
										render={({ field }) => (
											<InputField
												{...field}
												type='email'
												label='Email'
												trigger={trigger}
												fullWidth
											/>
										)}
									/>
									{errors.email !== undefined && (
										<FormHelperText color='error'>
											{errors?.email?.message}
										</FormHelperText>
									)}
								</FormControl>
								<FormControl name='phoneNum'>
									<Controller
										name='phoneNum'
										control={control}
										render={({ field }) => (
											<InputField
												{...field}
												type='phoneNum'
												label='Phone number'
												trigger={trigger}
												fullWidth
											/>
										)}
									/>
									{errors.phoneNum !== undefined && (
										<FormHelperText color='error'>
											{errors?.phoneNum?.message}
										</FormHelperText>
									)}
								</FormControl>
							</FieldsWrapper>
							<FieldsWrapper>
								<FormControl name='revenue'>
									<Controller
										name='revenue'
										control={control}
										render={({ field }) => (
											<InputField
												{...field}
												prefix='$ '
												type='revenue'
												label='Revenue'
												trigger={trigger}
												fullWidth
											/>
										)}
									/>
									{errors.revenue !== undefined && (
										<FormHelperText color='error'>
											{errors?.revenue?.message}
										</FormHelperText>
									)}
								</FormControl>
								<FormControl name='numOfClients'>
									<Controller
										name='numOfClients'
										control={control}
										render={({ field }) => (
											<InputField
												{...field}
												type='numOfClients'
												label='Number of clients'
												trigger={trigger}
												fullWidth
											/>
										)}
									/>
									{errors.numOfClients !== undefined && (
										<FormHelperText color='error'>
											{errors?.numOfClients?.message}
										</FormHelperText>
									)}
								</FormControl>
							</FieldsWrapper>
							<ButtonsWrapper>
								<Dialog.Close>
									<Button label='Cancel' fullWidth variant='secondary' />
								</Dialog.Close>
								<Button
									type='submit'
									label='Get a Demo'
									disabled={!isValid}
									fullWidth
								/>
							</ButtonsWrapper>
						</Form>
					</>
				)}
			</Content>
		</Dialog>
	)
}

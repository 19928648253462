// ** Icon Imports
import { Check } from '../../icons'

// ** Styled Components
import {
	RadioButtonLabel,
	RadioButtonWrapper,
	RadioButtonIndicator,
	RadioButtonItem
} from './styles'

interface IProps {
	id?: string
	value: string
	label?: string
	size?: 'sm' | 'lg'
}

export const RadioButton = ({ id, value, label, size = 'lg' }: IProps) => {
	return (
		<RadioButtonWrapper>
			<RadioButtonItem $size={size} value={value} id={id}>
				<RadioButtonIndicator $size={size}>
					<Check />
				</RadioButtonIndicator>
			</RadioButtonItem>
			{label !== undefined && (
				<RadioButtonLabel htmlFor={id}>{label}</RadioButtonLabel>
			)}
		</RadioButtonWrapper>
	)
}

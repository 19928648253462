// ** Third Party Imports
import styled from 'styled-components'

// ** Icon Imports
import { Logout } from '@/shared/ui/icons'

export const SidebarLogout = styled.div`
	width: 100%;
	display: flex;
	cursor: pointer;
	padding: 0.5rem 2.5rem;
	align-items: center;
	text-decoration: none;

	&:hover {
		background-color: ${({ theme }) => theme.pallette.background.sidebarDark};
	}
	& svg {
		margin-right: 1rem;
	}
`

export const LogoutIcon = styled(Logout)`
	& path {
		stroke: #fafafa;
	}
`

/**
 * UNCOMMENT ALL COMMENTS AFTER THE LAUNCH PHASE
 */

// ** React Imports
import { useEffect, useRef, useState } from 'react'

// ** Third Party Imports
import { useMediaQuery, useOnClickOutside } from 'usehooks-ts'

// ** Feature Imports
import { ScheduleDemoButton } from '@/features/home/ScheduleDemoButton'

// ** Component Imports
import { Button, IconButton, NavLink } from '@/shared/ui'

// ** Icon Imports
import {
	Home,
	Menu,
	Phone,
	XIcon,
	Login,
	LogoLarge,
	FileQuestion,
	UsersProfiles
} from '@/shared/ui/icons'

// ** Styled Components
import {
	Sidebar,
	MenuIcon,
	NavbarContent,
	NavbarLinks,
	NavbarWrapper,
	NavbarButtons,
	LoginButton
} from './styles'

export const Navbar = () => {
	// ** State
	const [isSidebarOpen, setIsSidebarOpen] = useState(false)

	// ** Hooks
	const sidebarRef = useRef<HTMLDivElement>(null)
	const isScreenMedium = useMediaQuery('(max-width: 992px)')

	useEffect(() => {
		if (!isScreenMedium) {
			setIsSidebarOpen(false)
		}
	}, [isScreenMedium])

	useOnClickOutside(sidebarRef, () => {
		setIsSidebarOpen(false)
	})

	const closeSidebar = () => {
		setIsSidebarOpen(false)
	}

	return (
		<NavbarWrapper>
			<NavbarContent>
				{/* Page logo */}
				<IconButton
					to='/home'
					icon={<LogoLarge />}
					width={8.125}
					height={2.688}
					ariaLabel='Go to home page'
				/>

				{/* Hamburger icon (shown only on <992px screens) */}
				<MenuIcon
					icon={isSidebarOpen ? <XIcon /> : <Menu />}
					width={1.5}
					height={1.5}
					ariaLabel='Toggle sidebar'
					onClick={() => {
						setIsSidebarOpen((prev) => !prev)
					}}
				/>

				{/* Desktop links (shown only on >992px screens) */}
				{!isScreenMedium && (
					<>
						<NavbarLinks>
							<NavLink to='/home' label='Home' icon={<Home />} />
							<NavLink to='/about' label='About us' icon={<UsersProfiles />} />
							<NavLink to='/faq' label='FAQ' icon={<FileQuestion />} />
							<NavLink to='/contact' label='Contact us' icon={<Phone />} />
						</NavbarLinks>
						<NavbarButtons>
							<ScheduleDemoButton />
							<Button to='/login' label='Log in' startIcon={<Login />} />
						</NavbarButtons>
					</>
				)}

				{/* Collapsible sidebar (shown only on <992px screens) */}
				<Sidebar ref={sidebarRef} $open={isSidebarOpen} onBlur={closeSidebar}>
					<NavbarLinks>
						<NavLink
							to='/home'
							label='Home'
							icon={<Home />}
							onClick={closeSidebar}
						/>
						<NavLink
							to='/about'
							label='About us'
							icon={<UsersProfiles />}
							onClick={closeSidebar}
						/>
						<NavLink
							to='/faq'
							label='FAQ'
							icon={<FileQuestion />}
							onClick={closeSidebar}
						/>
						<NavLink
							to='/contact'
							label='Contact us'
							icon={<Phone />}
							onClick={closeSidebar}
						/>
					</NavbarLinks>
					<ScheduleDemoButton />
					<LoginButton to='/login' label='Log in' startIcon={<Login />} />
				</Sidebar>
			</NavbarContent>
		</NavbarWrapper>
	)
}

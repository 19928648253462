// ** Third Party Imports
import { useRoutes } from 'react-router-dom'
import { wrapUseRoutes } from '@sentry/react'

// ** Hook Imports
import { useAppSelector } from '@/shared/lib/hooks'

// ** Route Imports
import { publicRoutes } from './publicRoutes'
import { protectedRoutes } from './protectedRoutes'

export const AppRoutes = () => {
	// ** Hooks
	const isAuth = useAppSelector((state) => state.session.isAuthenticated)

	const routes = isAuth ? protectedRoutes : publicRoutes

	const useSentryRoutes = wrapUseRoutes(useRoutes)
	const element = useSentryRoutes(routes)

	return <>{element}</>
}

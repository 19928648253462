// ** Third Party Imports
import styled, { css } from 'styled-components'
import { NavLink, type NavLinkProps } from 'react-router-dom'

// ** Widgets Imports
import { CreateNewWidget as CreateNewWidgetUI } from '../CreateNewWidget'

// ** Utility Imports
import { media, zIndex } from '@/shared/styles'

// ** Icon Imports
import {
	BarChart,
	ClientDashboard,
	CoinHand,
	UsersProfiles
} from '@/shared/ui/icons'

export const SidebarWrapper = styled.div`
	flex: 0 0 auto;
	width: 17.5rem;
	overflow-x: hidden;

	& p {
		color: ${({ theme }) => theme.pallette.geniiWhite};
	}

	${media.md} {
		width: 0;
	}
`

export const SidebarContent = styled.div<{ $isSidebarOpen: boolean }>`
	top: 0;
	left: 0;
	width: 17.5rem;
	color: #fafafa;
	height: 100%;
	display: flex;
	padding: 2rem 0rem;
	position: fixed;
	overflow-x: hidden;
	font-weight: 400;
	flex-direction: column;
	background: ${({ theme }) => theme.pallette.geniiBlack};
	z-index: ${zIndex.sidebar};
	box-shadow:
		4px 0px 12px 0px rgba(9, 18, 49, 0.25),
		4px 0px 7px 0px rgba(9, 18, 49, 0.15);

	${media.md} {
		transition: all 0.3s ease-in-out;
		padding: 1rem 0 2rem 0;
		${({ $isSidebarOpen }) =>
			!$isSidebarOpen &&
			css`
				transform: translateX(-100%);
				box-shadow: none;
			`}
	}
`

export const SidebarProfileLink = styled(NavLink)`
	display: flex;
	padding: 0.5rem 1.5rem 0.5rem 2.5rem;
	cursor: pointer;
	text-decoration: none;

	&.active,
	&:hover {
		background-color: ${({ theme }) => theme.pallette.background.sidebarDark};

		& p {
			color: ${({ theme }) =>
				theme.pallette.secondary.yellowBackground} !important;
		}
	}

	&.active {
		& p {
			font-weight: 600 !important;
		}
	}
`

export const Separator = styled.div`
	margin: 1.5rem 1rem;
	border-bottom: ${({ theme }) =>
		`1px solid ${theme.pallette.background.sidebarDark}`};
`

export const SidebarTopText = styled.div`
	margin-left: 1rem;

	& p:nth-child(2) {
		color: ${({ theme }) => theme.pallette.text.black50};
	}
`

export const SidebarLinks = styled.div`
	display: flex;
	align-items: flex-start;
	margin-bottom: 1.5rem;
	flex-direction: column;
`
export const SidebarLink = styled(NavLink)<NavLinkProps>`
	width: 100%;
	display: flex;
	padding: 0.5rem 2.5rem;
	align-items: center;
	text-decoration: none;

	&.collapsed {
		padding: 0.38rem 2.5rem 0.38rem 5rem;
		background-color: ${({ theme }) => theme.pallette.background.sidebarLight};

		&.group {
			padding: 0.5rem 2.5rem;
		}
	}

	&.active,
	&:hover {
		background-color: ${({ theme }) => theme.pallette.background.sidebarDark};
	}

	& svg {
		height: 1.5rem;
		width: 1.5rem;
		margin-right: 1rem;
	}
`

export const DashboardIcon = styled(BarChart)`
	& path {
		stroke: ${({ theme }) => theme.pallette.geniiWhite};
	}
`

export const ClientManagementIcon = styled(UsersProfiles)`
	& path {
		stroke: ${({ theme }) => theme.pallette.geniiWhite};
	}
`

export const ClientDashboardIcon = styled(ClientDashboard)`
	& path {
		fill: ${({ theme }) => theme.pallette.geniiWhite};
	}
`

export const TaxStrategiesIcon = styled(CoinHand)`
	& path {
		fill: ${({ theme }) => theme.pallette.geniiWhite};
	}
`

export const Header = styled.div`
	justify-content: end;
	padding: 0 1rem;
	display: none;

	${media.md} {
		display: flex;
	}
`

export const CreateNewWidget = styled(CreateNewWidgetUI)`
	margin: auto 2.5rem 1.5rem;
`

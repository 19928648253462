// ** Component Imports
import { Typography } from '@/shared/ui'

// ** Styled Components
import { LogoutIcon, SidebarLogout } from './styles'

// ** Action Imports
import { useLogoutMutation } from '@/entities/session/api/sessionApi'

export const LogoutButton = () => {
	// ** Hooks
	const [logout] = useLogoutMutation()

	return (
		<SidebarLogout
			onClick={() => {
				logout()
			}}
		>
			<LogoutIcon />
			<Typography variant='body1'>Logout</Typography>
		</SidebarLogout>
	)
}

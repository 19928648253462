// ** Third Party Imports
import styled from 'styled-components'

export const LayoutWrapper = styled.div`
	height: 100%;
	display: flex;
`

export const LayoutContentWrapper = styled.div`
	display: flex;
	flex-grow: 1;
	min-width: 0px;
	min-height: 100vh;
	flex-direction: column;
	background-color: ${({ theme }) => theme.pallette.geniiWhite};
`

export const LayoutPageContent = styled.main`
	width: 100%;
	margin: 0 auto;
	padding: 2rem;
	max-width: 90rem;
`

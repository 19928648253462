// ** Third Party Imports
import styled from 'styled-components'

export const BlankLayoutWrapper = styled.div`
	height: 100vh;
`

export const BlankLayoutContentWrapper = styled.div`
	display: flex;
	padding: 1.5rem;
	min-height: 100vh;
	overflow-x: hidden;
	align-items: center;
	justify-content: center;
	background:
		url(/images/onboarding-image.png),
		lightgray 50% / cover no-repeat;
`

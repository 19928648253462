// ** Component Imports

import { Loader } from '../../index'

// ** Styled Components
import { LoaderWrapper } from './styles'

// ** Type Imports
import { type CSSProperties } from 'react'

interface Props {
	fullHeight?: boolean
	styles?: CSSProperties
}

export const FallbackLoader = ({ fullHeight = true, styles }: Props) => {
	return (
		<LoaderWrapper $fullHeight={fullHeight} $styles={styles}>
			<Loader />
		</LoaderWrapper>
	)
}

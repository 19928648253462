// ** Third Party Imports
import styled from 'styled-components'

// ** Component Imports
import { Button, IconButton } from '@/shared/ui'

// ** Utility Imports
import { media, zIndex } from '@/shared/styles'

export const NavbarWrapper = styled.div`
	width: 100%;
	padding: 2rem;
	position: relative;
	background-color: ${({ theme }) => theme.pallette.geniiBlack};
`

export const NavbarContent = styled.div`
	gap: 1rem;
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	max-width: 74rem;
	align-items: center;
	justify-content: space-between;
`

export const NavbarLinks = styled.div`
	display: flex;
	column-gap: 2rem;
	align-items: center;
`

export const NavbarButtons = styled.div`
	gap: 1rem;
	display: flex;
`

export const Sidebar = styled.div<{ $open: boolean }>`
	top: 0;
	left: 0;
	width: 15rem;
	height: 100vh;
	z-index: ${zIndex.navbar};
	display: flex;
	padding: 2rem;
	position: absolute;
	transition: all 0.3s ease-in-out;
	flex-direction: column;
	background-color: ${({ theme }) => theme.pallette.geniiBlack};

	& > div {
		row-gap: 1rem;
		align-items: flex-start;
		margin-bottom: 2rem;
		flex-direction: column;
	}

	${({ $open }) =>
		!$open &&
		`
      transform: translateX(-100%);
  `}
`

export const MenuIcon = styled(IconButton)`
	display: none;

	& svg {
		& path {
			stroke: ${({ theme }) => theme.pallette.geniiWhite};
		}
	}

	${media.lg} {
		display: block;
	}
`

export const LoginButton = styled(Button)`
	margin-top: 1rem;
`

import styled from 'styled-components'
import { useAppDispatch } from '@/shared/lib/hooks'
import { setIsSidebarOpen } from '@/entities/layout'
import { XIcon } from '@/shared/ui/icons'
import { IconButton } from '@/shared/ui'

export const CloseIcon = styled(XIcon)`
	& path {
		stroke: ${({ theme }) => theme.pallette.geniiWhite};
	}
`

export const CloseSidebarIcon = () => {
	const dispatch = useAppDispatch()

	return (
		<IconButton
			icon={<CloseIcon />}
			ariaLabel='Close sidebar'
			height={1.5}
			width={1.5}
			onClick={() => {
				dispatch(setIsSidebarOpen(false))
			}}
		/>
	)
}

// ** React Imports
import { type RefObject } from 'react'

// Generate array of years between two provided values
export const generateYearsBetween = (startYear: number, endYear: number) => {
	const endDate = endYear || new Date().getFullYear()
	const years = []

	for (let i = startYear; i <= endDate; i++) {
		years.push(startYear)
		startYear++
	}

	return years
}

// Format date to international ISO
export const createUTCdateForISO = (date: Date) => {
	const offset = new Date().getTimezoneOffset()
	const myDate = date.getTime() - offset * 60 * 1000
	const dateAsISO = new Date(myDate).toISOString()

	return dateAsISO
}

export const createSearchParamsString = (
	params?: Record<string, string | number | undefined>
): string => {
	if (!params || Object.keys(params).length === 0) {
		return ''
	}

	const searchParams = new URLSearchParams()

	for (const key in params) {
		if (Object.prototype.hasOwnProperty.call(params, key)) {
			searchParams.append(key, params[key]?.toString() ?? '')
		}
	}

	return `?${searchParams.toString()}`
}

// ** Returns initials from string
export const getInitials = (string: string) =>
	string
		.split(/\s/)
		.reduce((response, word) => (response += word.slice(0, 1)), '')

// ** Returns full name from first and last name
type NameType = string | undefined | null
export const getFullName = (firstName: NameType, lastName: NameType) => {
	const first =
		firstName !== '' && firstName !== null && firstName !== undefined
			? firstName
			: null
	const last =
		lastName !== '' && lastName !== null && lastName !== undefined
			? lastName
			: null
	const fullName =
		first !== null && last !== null ? `${first} ${last}` : first ?? last ?? '-'

	return fullName
}

export const capitalizeFirstLetter = (string: string) => {
	return string.charAt(0).toUpperCase() + string.slice(1)
}

export const capitalizeUpperCaseWords = (string: string) => {
	return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

export const capitalizeFirstLetterOfEachWord = (string: string) => {
	return string.replace(/\b[a-z]/g, (char) => char.toUpperCase())
}

// ** Currency formatter
export const currencyFormatter = (
	value: number | undefined,
	currency?: string
) => {
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: currency ?? 'USD',
		minimumSignificantDigits: 1
	})

	return (
		formatter
			.format(value ?? 0)
			// if the price begins with digit, place the space after the digit
			.replace(/^([\d,.]+)/, '$1 ')
			// if the price ends with digit, place the space before the digit
			.replace(/([\d,.]+)$/, ' $1')
	)
}

// ** Currency Formatter for negative values
export const currencyFormatterNegative = (
	value: number | undefined,
	round?: boolean,
	prefix: string = '$',
	withDecimal: boolean = true
) => {
	let val = value

	if (val === undefined) {
		return ''
	}

	if (round) {
		val = roundNumber(val)
	}

	// return `${prefix} ` + val.toLocaleString('en-US')
	return `${prefix} ${val.toLocaleString(
		'en-US',
		withDecimal
			? {
					minimumFractionDigits: 2,
					maximumFractionDigits: 2
			  }
			: undefined
	)}`
}

/**
 * Formats a number into a currency string with an optional prefix.
 * Converts values to a more readable format (e.g., 100000 -> $100k).
 *
 * @param {number} value - The number to format.
 * @param {string} prefix - The currency prefix to use (default is '$').
 * @returns {string} - The formatted currency string.
 */
export const formatCurrencyToK = (value: number, prefix: string = '$') => {
	if (value >= 1000000) {
		return `${prefix}${Math.floor(value / 1000000)}M`
	} else if (value >= 1000) {
		return `${prefix}${Math.floor(value / 1000)}k`
	}
	return `${prefix}${value}`
}

/**
 * Constructs today's date in MM/DD/YYYY format.
 *
 * This function retrieves the current date, formats it with leading zeros for
 * single-digit months and days, and returns it as a string in the format
 * "MM/DD/YYYY".
 *
 * @returns {string} - The formatted date string representing today's date.
 *
 * @example
 * // returns "09/11/2024" if today is September 11, 2024
 * const date = constructTodaysDate();
 */
export const constructTodaysDate = (): string => {
	const today = new Date()
	const month = String(today.getMonth() + 1).padStart(2, '0') // months are 0-based
	const day = String(today.getDate()).padStart(2, '0')
	const year = today.getFullYear()

	return `${month}/${day}/${year}`
}

// ** Adds dot at the end of the string if it doesn't have one
export const addDotAtEnd = (str: string): string => {
	return str.endsWith('.') ? str : `${str}.`
}

/**
 * Returns the current quarter based on the current month.
 * @returns {Object} The current quarter object with a value and label.
 */
export const getCurrentQuarter = () => {
	const currentMonth = new Date().getMonth() + 1 // getMonth() returns month from 0 to 11, so add 1

	let quarter: { value: number; label: string } = {
		value: 0,
		label: ''
	}

	if (currentMonth >= 1 && currentMonth <= 3) {
		quarter = { value: 1, label: 'Q1' }
	} else if (currentMonth >= 4 && currentMonth <= 6) {
		quarter = { value: 2, label: 'Q2' }
	} else if (currentMonth >= 7 && currentMonth <= 9) {
		quarter = { value: 3, label: 'Q3' }
	} else {
		quarter = { value: 4, label: 'Q4' }
	}

	return quarter
}

/**
 * Get the specific date for each quarter based on the current date.
 *
 * @param {string} selectedQuarter - The quarter to get the date for.
 *                                    Should be one of "Q1", "Q2", "Q3", or "Q4".
 * @returns {string} The specific date of the specified quarter in the format "MM/DD/YYYY".
 *                  If the selected quarter is invalid, an error message is returned.
 *
 * @example
 * // If today is September 30, 2024
 * console.log(getQuarterDate('Q1')); // "04/15/2025" (next year's Q1)
 * console.log(getQuarterDate('Q2')); // "06/15/2025" (next year's Q2)
 * console.log(getQuarterDate('Q3')); // "09/15/2025" (next year's Q3)
 * console.log(getQuarterDate('Q4')); // "01/15/2025" (next year's Q4)
 */
export const getQuarterDate = (selectedQuarter: string) => {
	const currentDate = new Date()
	const currentYear = currentDate.getFullYear()
	let quarterDate

	switch (selectedQuarter.toLowerCase()) {
		case 'q1':
			// Q1: April 15
			quarterDate = new Date(
				currentYear + (currentDate.getMonth() >= 3 ? 1 : 0),
				3,
				15
			)
			break
		case 'q2':
			// Q2: June 15
			quarterDate = new Date(
				currentYear + (currentDate.getMonth() >= 5 ? 1 : 0),
				5,
				15
			)
			break
		case 'q3':
			// Q3: September 15
			quarterDate = new Date(
				currentYear + (currentDate.getMonth() >= 8 ? 1 : 0),
				8,
				15
			)
			break
		case 'q4':
			// Q4: January 15 of the next year
			quarterDate = new Date(
				currentYear + (currentDate.getMonth() >= 0 ? 1 : 0),
				0,
				15
			)
			break
		default:
			return 'Invalid quarter selected. Please select "Q1", "Q2", "Q3", or "Q4".'
	}

	// Format the date as MM/DD/YYYY
	const formattedDate = `
	${String(quarterDate.getMonth() + 1).padStart(2, '0')}/${String(
		quarterDate.getDate()
	).padStart(2, '0')}/${quarterDate.getFullYear()}`

	return formattedDate
}

/**
 * Removes the 'outlined' class from the specified array of HTMLElement references.
 *
 * @param refs - An array of RefObject<HTMLElement> references.
 * @returns A Promise that resolves once the class removal is done.
 */
export const removeOutlinedClass = async (
	refs: Array<RefObject<HTMLElement>>
) => {
	await new Promise<void>((resolve) => {
		refs.forEach((ref) => {
			if (ref.current) {
				const elements = ref.current.querySelectorAll('.outlined')
				elements.forEach((element) => {
					element.classList.remove('outlined')
				})
			}
		})

		// resolve the promise once the class removal is done
		resolve()
	})
}

/**
 * Adds a new class to target elements.
 *
 * @param refs - An array of React ref objects that reference the target elements.
 * @param targetClass - The class name of the target elements.
 * @param newClass - The class name to be added to the target elements.
 * @returns A promise that resolves once the class addition is done.
 */
export const addClassToTargetElements = async (
	refs: Array<RefObject<HTMLElement>>,
	targetClass: string,
	newClass: string
) => {
	await new Promise<void>((resolve) => {
		refs.forEach((ref) => {
			if (ref.current) {
				// Select all elements with the target class
				const elements = ref.current.querySelectorAll(`.${targetClass}`)
				elements.forEach((element) => {
					element.classList.add(newClass) // Add the new class to each targeted element
				})
			}
		})

		// Resolve the promise once the class addition is done
		resolve()
	})
}

/**
 * Check if provided date is valid
 * @see https://stackoverflow.com/questions/1353684/detecting-an-invalid-date-date-instance-in-javascript
 */
export const isDateValid = (date: Date) => {
	return date instanceof Date && !isNaN(date.getTime())
}

// Round number to the nearest integer
export const roundNumber = (num: number) => {
	return Math.round(num)
}

/**
 * Formats all numbers in a given string according to the 'en-US' locale.
 *
 * @param text - The input string containing numbers to be formatted.
 * @returns The formatted string with numbers formatted according to the 'en-US' locale.
 *
 * @example
 * 1000 => 1,000
 * 1234567 => 1,234,567
 */
export const formatNumber = (text: string) => {
	return text.replace(/\d+/g, (num) => {
		return new Intl.NumberFormat('en-US').format(Number(num))
	})
}

/**
 * Removes all commas from the given text.
 *
 * @param text - The string from which commas will be removed.
 * @returns The string without any commas.
 */
export const removeCommas = (text: string) => {
	// matches all commas in a string and replaces them with an empty string
	return text.replace(/,/g, '')
}

/**
 * Replaces the text content within the span tags with the new text.
 *
 * @param htmlStr - The HTML string containing the span tags.
 * @param newText - The new text to replace the existing text content.
 * @returns The updated HTML string with the new text content.
 */
export const replaceSpanText = (htmlStr: string, newText: string) => {
	// replaces the text content within the span tags with the new text
	// <span[^>]*> matches the opening span tag
	// (.*?) matches any text content within the span tags
	// <\/span> matches the closing span tag
	//
	// capture groups:
	// (<span[^>]*>) is captured as group 1 ($1)
	// (.*?) is the content inside the span tags, which is not captured for replacement
	// (<\/span>) is captured as Group 3 ($3)
	return htmlStr.replace(/(<span[^>]*>)(.*?)(<\/span>)/, `$1 ${newText} $3`)
}

/**
 * Extracts all numeric characters from a given string.
 *
 * @param str - The input string from which to extract numbers.
 * @returns A string containing only the numeric characters from the input string.
 */
export const extractNumbers = (str: string) => {
	// all matched non-digit characters will be replaced with nothing, effectively removing them from the string
	return str.replace(/\D/g, '') // \D matches any non-digit character
}

/**
 * Adds <strong> tags around the text content within <span> tags in the given HTML string.
 *
 * @param htmlStr - The HTML string to process.
 * @returns The updated HTML string with <strong> tags added around the text content within <span> tags.
 */
export const addStrongTagToMarkup = (htmlStr: string) => {
	return htmlStr.replace(
		/(<span[^>]*>)([^<]+)(<\/span>)/,
		(_, startTag: string, textContent: string, endTag: string) => {
			// add <strong> tags around the text content
			const updatedText = `<strong>${textContent.trim()}</strong>`
			return `${startTag}${updatedText}${endTag}`
		}
	)
}

/**
 * Converts a string representation of a number with commas into a number.
 *
 * @param value - The string value to be converted.
 * @returns The numeric value if conversion is successful, otherwise null.
 */
export const convertToNumber = (value: string) => {
	const number = value?.split(',').join('') ?? null
	return number ? +number : null
}

/**
 * Creates a query string from an object.
 *
 * @param obj - An object containing key-value pairs to be converted into a query string.
 *              The values can be of type string, number, array, or boolean.
 *              If the object is not provided or is empty, an empty string is returned.
 * @returns A query string starting with '?' followed by the key-value pairs from the object.
 *          If the object is empty or not provided, an empty string is returned.
 */
export const createQueryString = (obj?: Record<string, any> | void): string => {
	if (!obj) return ''
	const filteredObj = Object.entries(obj).reduce<Record<string, any>>(
		(acc, [key, value]) => {
			const isValidString = typeof value === 'string' && value.trim() !== ''
			const isValidNumber = typeof value === 'number' && !Number.isNaN(value)
			const isArray = Array.isArray(value)
			const isBoolean = typeof value === 'boolean'

			if (isValidString || isValidNumber || isArray || isBoolean) {
				acc[key] = value
			}
			return acc
		},
		{}
	)
	const queryString = new URLSearchParams(filteredObj).toString()
	return '?' + queryString
}

// ** Third Party Imports
import styled from 'styled-components'

// ** Type Imports
import { type CSSProperties } from 'react'

export const LoaderWrapper = styled.div<{
	$fullHeight?: boolean
	$styles?: CSSProperties
}>`
	${({ $fullHeight }) => $fullHeight && `height: 100vh;`}
	display: flex;
	align-items: center;
	justify-content: center;
	${({ $styles }) => ({ ...$styles })}
`

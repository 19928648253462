// ** Third Party Imports
import { Navigate, type RouteObject } from 'react-router-dom'

// ** Utility Imports
import { lazyImport } from '@/shared/lib'

// ** Layout Imports
import { LayoutWithSidebar } from '@/app/layouts/LayoutWithSidebar'
import { DashboardLayout } from '@/app/layouts/DashboardLayout'

// ** Route Imports
const { DashboardPage } = lazyImport(
	async () => await import('@/pages/dashboard'),
	'DashboardPage'
)
const { ClientManagementPage } = lazyImport(
	async () => await import('@/pages/client-management'),
	'ClientManagementPage'
)
const { CreateClientPage } = lazyImport(
	async () => await import('@/pages/client-management'),
	'CreateClientPage'
)
const { EditClientPage } = lazyImport(
	async () => await import('@/pages/client-management'),
	'EditClientPage'
)
const { CreateCompanyPage } = lazyImport(
	async () => await import('@/pages/company-management'),
	'CreateCompanyPage'
)
const { EditCompanyPage } = lazyImport(
	async () => await import('@/pages/company-management'),
	'EditCompanyPage'
)
const { BusinessSummaryPage } = lazyImport(
	async () => await import('@/pages/client-management'),
	'BusinessSummaryPage'
)
const { TaxStrategiesPage } = lazyImport(
	async () => await import('@/pages/tax-strategies'),
	'TaxStrategiesPage'
)
const { TaxStrategyPage } = lazyImport(
	async () => await import('@/pages/tax-strategies'),
	'TaxStrategyPage'
)
const { CreatePlanPage } = lazyImport(
	async () => await import('@/pages/plan-management'),
	'CreatePlanPage'
)
const { EditPlanPage } = lazyImport(
	async () => await import('@/pages/plan-management'),
	'EditPlanPage'
)
const { PlanReportPage } = lazyImport(
	async () => await import('@/pages/plan-management'),
	'PlanReportPage'
)
const { ManagePlanDetailsPage } = lazyImport(
	async () => await import('@/pages/plan-management'),
	'ManagePlanDetailsPage'
)
const { ClientDashboardPage } = lazyImport(
	async () => await import('@/pages/client-dashboard'),
	'ClientDashboardPage'
)
const { UserProfilePage } = lazyImport(
	async () => await import('@/pages/user-profile'),
	'UserProfilePage'
)
const { Error404Page } = lazyImport(
	async () => await import('@/pages/error-404'),
	'Error404Page'
)
const { ChatHistoryPage } = lazyImport(
	async () => await import('@/pages/chat-history'),
	'ChatHistoryPage'
)
const { TaxStrategyTemplatePage } = lazyImport(
	async () => await import('@/pages/tax-strategies'),
	'TaxStrategyTemplatePage'
)

export const protectedRoutes: RouteObject[] = [
	{
		element: <LayoutWithSidebar />,
		children: [
			{
				path: '/',
				element: <DashboardPage />
			},
			// ** /user-profile
			{
				path: '/user-profile',
				element: <DashboardLayout />,
				children: [
					{
						index: true,
						element: <UserProfilePage />
					},
					{
						path: 'chat-history/:clientId/:planId',
						element: <ChatHistoryPage />
					}
				]
			},
			// ** /client-management
			{
				path: '/client-management',
				element: <DashboardLayout />,
				children: [
					{
						index: true,
						element: <ClientManagementPage />
					},
					{
						path: 'create-client',
						element: <CreateClientPage />
					},
					{
						path: 'edit-client/:id',
						element: <EditClientPage />
					},
					{
						path: 'create-company/*',
						element: <CreateCompanyPage />
					},
					{
						path: 'edit-company/*',
						element: <EditCompanyPage />
					}
				]
			},
			// ** /client-dashboard
			{
				path: '/client-dashboard',
				element: <DashboardLayout />,
				children: [
					{
						index: true,
						element: <ClientDashboardPage />
					},
					{
						path: 'plan-details/:clientId/:planId',
						element: <ManagePlanDetailsPage />
					},
					{
						path: 'plan-details/:clientId/:planId/edit',
						element: <EditPlanPage />
					},
					{
						path: 'plan-details/:clientId/:planId/report',
						element: <PlanReportPage />
					},
					{
						path: 'business-summary/:id',
						element: <BusinessSummaryPage />
					},
					{
						path: 'create-plan/*',
						element: <CreatePlanPage />
					}
				]
			},
			// ** /tax-strategies
			{
				path: '/tax-strategies',
				element: <DashboardLayout />,
				children: [
					{
						index: true,
						element: <TaxStrategiesPage />
					},
					{
						path: 'tax-strategy-details/:id',
						element: <TaxStrategyPage />
					},
					{
						path: '/tax-strategies/template/:templateId',
						element: <TaxStrategyTemplatePage />
					}
				]
			},
			// ** rest
			{
				path: '/login',
				element: <Navigate to='/' replace />
			},
			{
				path: '*',
				element: <Error404Page />
			}
		]
	}
]

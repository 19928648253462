interface IProps {
	label?: string
	value?: string
}

/**
 *
 * This component is written in plain HTML because ApexCharts
 * accepts only plain HTML as a string for a custom tooltip.
 * All styles are inline because ApexCharts doesn't accept styled components.
 * Also, when using this component as a custom tooltip in charts,
 * add the following value to tooltip options: cssClass: 'custom-chart-tooltip-class'.
 */

export const ChartTooltip = ({ label, value }: IProps) => {
	return (
		<div
			style={{
				border: 'none',
				padding: '0.5rem',
				boxShadow: '0px 2px 8px 0px rgba(9, 106, 99, 0.25)',
				borderRadius: '0.5rem',
				backgroundColor: '#fafafa'
			}}
		>
			{label !== undefined && (
				<p
					style={{
						color: '#091231',
						fontSize: '0.625rem',
						textAlign: 'center',
						fontWeight: '400'
					}}
				>
					{label}
				</p>
			)}
			{value !== undefined && (
				<p
					style={{
						color: '#091231',
						fontSize: '0.75rem',
						textAlign: 'center',
						fontWeight: '600'
					}}
				>
					{value}
				</p>
			)}
		</div>
	)
}

// ** Third Party Imports
import { type CSSProperties } from 'react'
import styled from 'styled-components'

export const Container = styled.div`
	position: relative;
`

export const Label = styled.label<{
	$isError: boolean
	$isSuccess: boolean
	$isFloating: boolean
	$isDisabled: boolean
	$styles: CSSProperties
}>`
	top: 50%;
	left: 0.75rem;
	color: ${({ theme }) => theme.pallette.text.black50};
	z-index: 2;
	position: absolute;
	transform: translateY(-50%);
	font-size: 0.875rem;
	transition: 0.3s ease all;
	font-weight: 400;
	line-height: 1;
	background-color: ${({ theme }) => theme.pallette.geniiWhite};
	pointer-events: none;

	${({ theme, $isFloating }) =>
		$isFloating &&
		`
      top: 0;
      left: 0.75rem;
      padding: 0 0.25rem;
      font-size: 0.625rem;
      color: ${theme.pallette.text.black75};
  `}

	${({ theme, $isSuccess }) =>
		$isSuccess && `color: ${theme.pallette.success.main};`}

	${({ theme, $isError }) => $isError && `color: ${theme.pallette.error.main};`}

  ${({ $isDisabled }) => $isDisabled && `color: rgba(132, 136, 152, 0.5);`}

  ${({ $isSuccess, $isDisabled }) =>
		$isSuccess && $isDisabled && `color: rgba(126, 173, 130, 0.5);`}

	${({ $styles }) => ({ ...$styles })}
`

export const StyledOption = styled.div`
	color: ${({ theme }) => theme.pallette.text.black75};
	cursor: pointer;
	display: flex;
	outline: none;
	align-items: center;
	background-color: transparent;

	&:not(:last-child) {
		border-bottom: 1px solid ${({ theme }) => theme.pallette.text.black50};
	}

	&:hover {
		background-color: ${({ theme }) =>
			theme.pallette.primary.greenBackground60};
	}

	/* Style select item icon */
	& svg {
		margin-right: 0.75rem;

		&:not(.exclude-icon) {
			stroke: ${({ theme }) => theme.pallette.success.main};
		}
	}

	/* Disable native background on item first click */
	& > div:active {
		background-color: transparent;
	}
`

// ** Type Imports
import { type CSSProperties } from 'react'

// ** Styled Components
import { LoaderWrapper } from './styles'

interface IProps {
	className?: string
	styles?: CSSProperties
	divStyles?: CSSProperties
}

export const Loader = ({ className, styles, divStyles }: IProps) => {
	return (
		<LoaderWrapper
			role='alert'
			aria-busy='true'
			className={className}
			$styles={styles}
			$divStyles={divStyles}
		>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</LoaderWrapper>
	)
}

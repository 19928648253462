// ** Styled Components
import { AvatarRoot, AvatarImage, AvatarFallback } from './styles'

interface IProps {
	src?: string
	/**
	 * Provide width value along with unit (e.g. 2rem or 24px)
	 */
	width: string
	/**
	 * Provide height value along with unit (e.g. 2rem or 24px)
	 */
	height: string
	fallbackText?: string
	className?: string
}

export const Avatar = ({
	src,
	width,
	height,
	fallbackText,
	className
}: IProps) => {
	return (
		<AvatarRoot $width={width} $height={height} className={className}>
			<AvatarImage src={src} alt='' />
			<AvatarFallback>{fallbackText}</AvatarFallback>
		</AvatarRoot>
	)
}

function calculateLightenFactor(
	totalItems: number,
	currentItem: number
): number {
	const maxLightenFactor = Math.max(
		0.4 - Math.floor((totalItems - 1) / 8) * 0.1,
		0.1
	)

	// Adjust this formula as needed
	const factor = 0.1 * (totalItems - currentItem + 1)

	// Ensure the factor doesn't exceed the maximum
	return Math.min(factor, maxLightenFactor)
}

function lightenColor(hex: string, factor: number): string {
	// Convert hex to RGB
	let r: number = parseInt(hex.substring(1, 3), 16)
	let g: number = parseInt(hex.substring(3, 5), 16)
	let b: number = parseInt(hex.substring(5, 7), 16)

	// Calculate lighter shade
	r = Math.min(255, Math.round(r + (255 - r) * factor))
	g = Math.min(255, Math.round(g + (255 - g) * factor))
	b = Math.min(255, Math.round(b + (255 - b) * factor))

	// Convert back to hex
	const result = `#${((r << 16) | (g << 8) | b).toString(16).padStart(6, '0')}`

	return result
}

export function generateLighterShades(
	baseColor: string,
	numberOfItems: number
): string[] {
	const colors: string[] = [baseColor]

	for (let i = 1; i < numberOfItems; i++) {
		const previousColor = colors[i - 1]
		const lightenFactor = calculateLightenFactor(numberOfItems, i)
		colors.push(lightenColor(previousColor, lightenFactor))
	}

	return colors
}

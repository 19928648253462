// ** React Imports
import { Outlet } from 'react-router-dom'
import { Suspense } from 'react'

// ** Component Imports
import { FallbackLoader } from '../../../shared/ui/elements/FallbackLoader'

// ** Styled Components
import { BlankLayoutContentWrapper, BlankLayoutWrapper } from './styles'

export const BlankLayout = () => {
	return (
		<BlankLayoutWrapper>
			<BlankLayoutContentWrapper>
				<Suspense fallback={<FallbackLoader fullHeight={false} />}>
					<Outlet />
				</Suspense>
			</BlankLayoutContentWrapper>
		</BlankLayoutWrapper>
	)
}
